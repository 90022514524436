import { memo, type FC } from 'react';
import { isEqual } from 'lodash/fp';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

type Props = { className?: string };

export const WarningIcon: FC<Props> = memo(
    ({ className }: Props) => (
        <FontAwesomeIcon
            data-testid="warning-icon"
            className={clsx('tw-inline-block tw-text-coral', className)}
            icon={faTriangleExclamation}
        />
    ),
    isEqual,
);
