import PerformanceTest from './PerformanceTest';

/*
    This test tracks how long it takes to fetch and store content for a stream.

    It only works in a situation where offline mode is enabled.

    Since the user will automatically start downloading content for offline mode,
    we don't need to do anything in runTest(). But, this test will only work if it
    is started at a time when the user is downloading content for offline mode.

    The test will continue until it observes `offline_mode:stored_stream` for `dataPointLimit` streams,
    at which point it will complete.
*/
export default class StoreStreamTest extends PerformanceTest {
    name = 'StoreStream';
    #counter = 0;
    #repetitions = 20;
    #listeners: (() => void)[] = [];
    timerEventTypes = ['offline_mode:stored_stream'];

    // Once we have collected enough data points, we stop the test
    afterRecordDataPoint() {
        this.#counter += 1;

        this.logProgress(`${this.#counter} of ${this.#repetitions}`);
        if (this.#counter >= this.#repetitions) {
            this.completeTest();
        }
    }
}
