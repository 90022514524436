import { memo, useCallback } from 'react';
import { useNavigationHelper } from 'FrontRoyalAngular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/pro-solid-svg-icons/faReply';
import { Button } from '@mui/material';
import ListPlaylistExamEvaluations from './ListPlaylistExamEvaluations';
import ShowPlaylistExamEvaluation from './ShowPlaylistExamEvaluation';

type Props = {
    playlistExamEvaluationId: string | null;
};

function PlaylistExamEvaluationsComponent({ playlistExamEvaluationId }: Props) {
    const { loadRoute } = useNavigationHelper();

    const gotoIndex = useCallback(() => {
        loadRoute('/editor?section=exams');
    }, [loadRoute]);

    return (
        <>
            {playlistExamEvaluationId && (
                <Button className="tw-my-5 tw-flex tw-items-center tw-text-coral" onClick={() => gotoIndex()}>
                    <FontAwesomeIcon className="tw-me-1" icon={faReply} />
                    <span className="">Back to Exams List</span>
                </Button>
            )}

            <div className="tw-bg-white tw-p-6">
                {!playlistExamEvaluationId && <ListPlaylistExamEvaluations />}
                {playlistExamEvaluationId && (
                    <ShowPlaylistExamEvaluation playlistExamEvaluationId={playlistExamEvaluationId} />
                )}
            </div>
        </>
    );
}

export const PlaylistExamEvaluations = memo(
    PlaylistExamEvaluationsComponent,
) as typeof PlaylistExamEvaluationsComponent;

export default PlaylistExamEvaluations;
