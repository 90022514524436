import { type SafeApply } from 'SafeApply';

export default function navigateToAccountingOneStream($injector: angular.auto.IInjectorService) {
    const $rootScope = $injector.get('$rootScope');
    const $location = $injector.get('$location');
    const safeApply = $injector.get('safeApply') as SafeApply;

    const path = '/course/50eeda2d-1b44-4a1d-b425-dead8982526f';
    if ($location.url() === path) {
        return Promise.resolve();
    }

    let cancelListener;
    const promise = new Promise(resolve => {
        cancelListener = $rootScope.$on('renderedStreamDashboard', resolve);
    });

    $location.url(path);
    safeApply($rootScope);
    return promise.then(cancelListener);
}
