/* eslint-disable react/jsx-wrap-multilines */
import clsx from 'clsx';
import { type Stream } from 'Lessons';
import { isEqual, startCase } from 'lodash/fp';
import { type ExamChallengeEntry } from 'PlaylistExamEvaluation';
import { memo, type ReactNode, type FC } from 'react';
import { twMerge } from 'Utils/customTwMerge';
import { type FrameDescriptor } from './CoverageMaps.types';
import { FrameLinkListByLesson } from './FrameLinkListByLesson';
import { PlaylistExamEvaluationMarkdown } from './PlaylistExamEvaluationMarkdown';
import { WarningIcon } from './WarningIcon';

const classes = {
    container: clsx(['tw-w-[90%]', 'tw-pb-4', 'tw-ps-[42px]', 'tw-pt-4']),
    calloutBox: {
        container: clsx([
            'tw-mb-4',
            'tw-flex',
            'tw-flex-col',
            'tw-rounded-lg',
            'tw-border',
            'tw-p-3',
            'tw-leading-[1.1em]',
        ]),
    },
    warningBox: {
        container: 'tw-border-coral',
        header: {
            container: clsx([
                'tw-mb-4',
                'tw-flex',
                'tw-items-center',
                'tw-justify-start',
                'tw-font-semibold',
                'tw-text-coral',
            ]),
            warningIcon: 'tw-me-2',
        },
        children: 'tw-ps-[23px]',
    },
    unfair: {
        container: clsx(['tw-mb-8', 'tw-bg-white']),
    },
    levelOfThinking: {
        header: {
            container: 'tw-mb-4',
            label: 'tw-font-bold',
        },
        explanation: {
            container: 'tw-mb-5',
        },
        calloutBox: clsx(['tw-border-beige-medium', 'tw-bg-white', 'tw-p-5', 'tw-leading-[1.1em]']),
    },
    instructionalScreens: {
        container: 'tw-mb-4',
        header: clsx(['tw-mb-4', 'tw-font-bold']),
        frameLinkList: 'tw-ms-5',
    },
    noInstructionalScreens: {
        warningBox: 'tw-bg-white',
        container: 'tw-mt-2',
        possibleMatchesHeader: 'tw-mb-4',
        frameLinkList: 'tw-ms-5',
    },
};

function CalloutBox({ children, className }: { children: ReactNode; className?: string }) {
    return <div className={twMerge(classes.calloutBox.container, className)}>{children}</div>;
}

function WarningBox({ children, header, className }: { children: ReactNode; header: ReactNode; className?: string }) {
    return (
        <CalloutBox className={clsx(classes.warningBox.container, className)}>
            <div className={classes.warningBox.header.container}>
                <WarningIcon className={classes.warningBox.header.warningIcon} />
                {header}
            </div>
            <div className={classes.warningBox.children}>{children}</div>
        </CalloutBox>
    );
}

function Unfair({
    fairnessExplanation,
    frameDescriptors,
}: {
    fairnessExplanation: string;
    frameDescriptors: FrameDescriptor[];
}) {
    return (
        <WarningBox header="Suggestion" className={classes.unfair.container}>
            <PlaylistExamEvaluationMarkdown frameDescriptors={frameDescriptors}>
                {fairnessExplanation}
            </PlaylistExamEvaluationMarkdown>
        </WarningBox>
    );
}

function LevelOfThinking({
    levelOfThinking,
    levelOfThinkingExplanation,
    frameDescriptors,
    className,
}: {
    levelOfThinking: string;
    levelOfThinkingExplanation: string;
    className: string;
    frameDescriptors: FrameDescriptor[];
}) {
    return (
        <div className={className}>
            <div className={classes.levelOfThinking.header.container}>
                <span className={classes.levelOfThinking.header.label}>Level of Thinking: </span>
                {startCase(levelOfThinking)}
            </div>
            <CalloutBox className={classes.levelOfThinking.calloutBox}>
                <PlaylistExamEvaluationMarkdown frameDescriptors={frameDescriptors}>
                    {levelOfThinkingExplanation}
                </PlaylistExamEvaluationMarkdown>
            </CalloutBox>
        </div>
    );
}

type Props = {
    examChallengeEntry: ExamChallengeEntry;
    streams: Stream<true>[];
};

export const ExamChallengeBody: FC<Props> = memo(({ examChallengeEntry, streams }) => {
    const candidateInstructionalFrameDescriptors = streams
        .flatMap(s => s.lessons)
        .flatMap(lesson =>
            lesson.frames
                .filter(frame => examChallengeEntry.candidateInstructionalFrameIds.includes(frame.id))
                .map(frame => ({
                    lessonId: lesson.id,
                    frameId: frame.id,
                    frameIndex: lesson.frames.indexOf(frame),
                })),
        );

    return (
        <div className={classes.container}>
            {!examChallengeEntry.fair && examChallengeEntry.fairnessExplanation ? (
                <Unfair
                    fairnessExplanation={examChallengeEntry.fairnessExplanation}
                    frameDescriptors={candidateInstructionalFrameDescriptors}
                />
            ) : null}

            {examChallengeEntry.levelOfThinking && examChallengeEntry.levelOfThinkingExplanation && (
                <LevelOfThinking
                    className={classes.levelOfThinking.explanation.container}
                    levelOfThinking={examChallengeEntry.levelOfThinking}
                    levelOfThinkingExplanation={examChallengeEntry.levelOfThinkingExplanation}
                    frameDescriptors={candidateInstructionalFrameDescriptors}
                />
            )}

            {examChallengeEntry.instructionalFrameIds.length > 0 && (
                <div className={classes.instructionalScreens.container}>
                    <p className={classes.instructionalScreens.header}>Instructional Screens:</p>
                    <div className={classes.instructionalScreens.frameLinkList}>
                        <FrameLinkListByLesson frameIds={examChallengeEntry.instructionalFrameIds} streams={streams} />
                    </div>
                </div>
            )}
            {examChallengeEntry.instructionalFrameIds.length === 0 && (
                <>
                    {examChallengeEntry.noInstructionalFramesExplanation && (
                        <WarningBox
                            header="No Instructional Screens Identified"
                            className={classes.noInstructionalScreens.warningBox}
                        >
                            <PlaylistExamEvaluationMarkdown frameDescriptors={candidateInstructionalFrameDescriptors}>
                                {examChallengeEntry.noInstructionalFramesExplanation}
                            </PlaylistExamEvaluationMarkdown>
                        </WarningBox>
                    )}
                    <div className={classes.noInstructionalScreens.container}>
                        <p className={classes.noInstructionalScreens.possibleMatchesHeader}>
                            The following instructional screens were considered as possible matches for this challenge:
                        </p>
                        <div className={classes.noInstructionalScreens.frameLinkList}>
                            <FrameLinkListByLesson
                                frameIds={examChallengeEntry.candidateInstructionalFrameIds}
                                streams={streams}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}, isEqual);
