import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { memo, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { playlistEditorApi } from 'Playlists';
import { lessonsEditorApi, type Stream } from 'Lessons';
import { Box, Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { formattedUserFacingDateTime } from 'DateHelpers';
import { playlistExamEvaluationsApi } from './PlaylistExamEvaluationsApi';
import getCoverageMaps from './getCoverageMaps';
import { ShowExamFrames } from './ShowExamFrames';
import { ShowAllStreamsCoverage } from './ShowAllStreamsCoverage';

const { useGetExamEvaluationQuery } = playlistExamEvaluationsApi;
const { useGetPlaylistQuery } = playlistEditorApi;
const { useGetStreamsQuery } = lessonsEditorApi;

const TAB_EXAM_SCREEN_BREAKDOWN = 'exam_screen_breakdown';
const TAB_EXAM_CONTENT_SOURCES = 'exam_content_sources';

type Props = {
    playlistExamEvaluationId: string;
};

function useData({ playlistExamEvaluationId }: { playlistExamEvaluationId: string }) {
    const { data: playlistExamEvaluationResponse, isLoading: playlistExamEvaluationIsLoading } =
        useGetExamEvaluationQuery(playlistExamEvaluationId);
    const playlistExamEvaluation = playlistExamEvaluationResponse?.contents.playlistExamEvaluations[0];

    const { data: playlistResponse, isLoading: playlistIsLoading } = useGetPlaylistQuery(
        playlistExamEvaluation ? playlistExamEvaluation.playlistId : skipToken,
    );
    const playlist = playlistResponse?.contents.playlists[0];

    const { data: streamsResponse, isLoading: streamsAreLoading } = useGetStreamsQuery(
        playlist
            ? {
                  filters: { locale_pack_id: playlist.streamEntries.map(entry => entry.localePackId) },
                  load_full_content_for_all_lessons: true,
              }
            : skipToken,
    );
    const sortedIds = playlist?.streamEntries.map(entry => entry.localePackId);
    const streams =
        sortedIds &&
        streamsResponse?.contents.lessonStreams
            .slice()
            .sort((s1, s2) => (sortedIds.indexOf(s1.localePack.id) > sortedIds.indexOf(s2.localePack.id) ? 1 : -1));

    const isLoading = playlistExamEvaluationIsLoading || playlistIsLoading || streamsAreLoading;

    return {
        playlistExamEvaluation,
        playlist,
        streams: streams as Stream<true>[], // Since we set load_full_content_for_all_lessons to true, we can reliably cast this to Stream<true>
        isLoading,
    };
}

const classes = {
    tabs: {
        container: 'tw-mb-7',
        tab: 'tw-me-3',
    },
    examTitle: clsx(['tw-mb-4', 'tw-text-2xl', 'tw-font-bold']),
    examInfo: {
        heading: 'tw-font-bold',
    },
};

function ShowPlaylistExamEvaluationComponent({ playlistExamEvaluationId }: Props) {
    const { playlistExamEvaluation, isLoading, streams } = useData({ playlistExamEvaluationId });
    const [currentTab, setCurrentTab] = useState<string>(TAB_EXAM_SCREEN_BREAKDOWN);
    const lastGeneratedAt =
        playlistExamEvaluation && formattedUserFacingDateTime(1000 * playlistExamEvaluation.lastGeneratedAt);
    const examStream = useMemo(() => streams?.find(stream => stream.exam), [streams]);

    const coverageMaps = playlistExamEvaluation && streams && getCoverageMaps(playlistExamEvaluation, streams);

    return (
        <>
            <Tabs
                className={classes.tabs.container}
                defaultValue={TAB_EXAM_SCREEN_BREAKDOWN}
                value={currentTab}
                onChange={(_event: React.SyntheticEvent, newValue: string) => {
                    setCurrentTab(newValue);
                }}
            >
                <Tab value={TAB_EXAM_SCREEN_BREAKDOWN} className={classes.tabs.tab} label="Exam Screen Breakdown" />
                <Tab value={TAB_EXAM_CONTENT_SOURCES} className={classes.tabs.tab} label="Exam Content Sources" />
            </Tabs>

            {examStream && (
                <>
                    <h1 className={classes.examTitle}>{examStream.title}</h1>
                    <Box
                        sx={{
                            border: '1px solid lightgray',
                            borderRadius: '8px',
                            padding: '16px',
                            display: 'inline-block',
                            margin: '0 0 30px',
                        }}
                    >
                        <div data-testid="exam-and-playlist-info">
                            <p>
                                <strong className={classes.examInfo.heading}>Exam Tag:</strong> ({examStream.tag})
                            </p>
                            <p>
                                <strong className={classes.examInfo.heading}>Playlist:</strong> (
                                {playlistExamEvaluation?.playlistTag}) {playlistExamEvaluation?.playlistTitle}
                            </p>
                            <p>
                                <strong className={classes.examInfo.heading}>Exam Evaluation last updated:</strong>{' '}
                                {lastGeneratedAt}
                            </p>
                        </div>
                    </Box>
                </>
            )}

            {isLoading && <FrontRoyalSpinner />}
            {!isLoading && coverageMaps && (
                <>
                    {currentTab === TAB_EXAM_CONTENT_SOURCES && (
                        <ShowAllStreamsCoverage
                            streams={streams}
                            examStream={examStream!}
                            coverageMaps={coverageMaps}
                        />
                    )}

                    {currentTab === TAB_EXAM_SCREEN_BREAKDOWN && playlistExamEvaluation && (
                        <ShowExamFrames streams={streams} coverageMaps={coverageMaps} />
                    )}
                </>
            )}
        </>
    );
}

export const ShowPlaylistExamEvaluation = memo(
    ShowPlaylistExamEvaluationComponent,
) as typeof ShowPlaylistExamEvaluationComponent;

export default ShowPlaylistExamEvaluation;
