/* eslint-disable react/jsx-wrap-multilines */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpandableCardList, ExpandableCardListContext } from 'ExpandableContainer';
import {
    type Lesson,
    type Stream,
    type Frame,
    type ChallengesTutorBotDescription,
    type ChallengeDescription,
    frameEditorLink,
} from 'Lessons';
import { isEqual, startCase } from 'lodash/fp';
import { memo, useContext, useMemo, useState, type FC } from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { type CoverageMaps } from './CoverageMaps.types';
import { ExamChallengeCard } from './ExamChallengeCard';
import { type ExamChallengeEntry, type ExamFrameEntry } from './PlaylistExamEvaluation.types';
import { WarningIcon } from './WarningIcon';

type Props = {
    streams: Stream<true>[];
    coverageMaps: CoverageMaps;
};

const challengeShouldHaveWarning = (challenge: ExamChallengeEntry) => !challenge.fair;

const LEVELS_OF_THINKING = {
    // These keys are in the order that they should be displayed in the pie chart
    recall: { labelColor: '#FFA726' },
    follow_procedure: { labelColor: '#C91B63' },
    apply_concepts: { labelColor: '#00A3A0' },
    demonstrate_judgement: { labelColor: '#2E96FF' },
    interpret_data: { labelColor: '#B800D8' },
    synthesize_concepts: { labelColor: '#2731C8' },
    unknown: { labelColor: '#EF5350' },
};

const cardClasses = {
    header: {
        container: clsx('tw-flex tw-w-full'),
        iconContainer: clsx([
            'tw-flex',
            'tw-shrink-0',
            'tw-grow-0',
            'tw-basis-5',
            'tw-justify-center',
            'tw-self-start',
        ]),
        warningIcon: clsx(['-tw-ms-3', 'tw-mt-2', 'tw-text-lg']),
        descriptionContainer: clsx(['tw-mx-2', 'tw-flex', 'tw-grow', 'tw-flex-col', 'tw-justify-start']),
        screen: {
            container: clsx(['tw-mb-1', 'tw-font-bold']),
            screenNumber: 'tw-text-md',
            frameLink: clsx(['tw-ml-2', 'tw-inline-block', 'tw-text-xxs', 'tw-text-blue']),
            tutorBotDescription: {
                container: clsx(['tw-pe-3', 'tw-leading-[1.1rem]']),
                mainText: (open: boolean) => clsx(!open && 'tw-line-clamp-1 tw-overflow-ellipsis'),
            },
        },
    },
    body: {
        container: 'tw-p-5',
        noChallengesMessage: clsx(['tw-m-2', 'tw-p-2', 'tw-italic', 'tw-text-gray']),
    },
    cardClassName: clsx(['tw-py-2', 'tw-px-2', 'tw-mb-4']),
    buttonClassName: clsx(['tw-items-start', 'tw-mt-3']),
};

function Header({
    frame,
    lesson,
    frameIndex,
    examFrameEntry,
}: {
    lesson: Lesson<true>;
    frame: Frame;
    frameIndex: number;
    examFrameEntry: ExamFrameEntry;
}) {
    const hasChallengeWithWarning = examFrameEntry.challenges?.find(challengeShouldHaveWarning);
    const { open } = useContext(ExpandableCardListContext);

    return (
        <div className={cardClasses.header.container}>
            <div className={cardClasses.header.iconContainer}>
                {hasChallengeWithWarning ? <WarningIcon className={cardClasses.header.warningIcon} /> : null}
            </div>
            <div className={cardClasses.header.descriptionContainer}>
                <div className={cardClasses.header.screen.container}>
                    <span className={cardClasses.header.screen.screenNumber}>Screen {frameIndex + 1}</span>
                    <a
                        href={frameEditorLink({ lessonId: lesson.id, frameId: frame.id })}
                        target="_blank"
                        rel="noreferrer"
                        className={cardClasses.header.screen.frameLink}
                        onClick={event => {
                            event.stopPropagation(); // Prevents the event from bubbling up and triggering the accordion to open or close
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </a>
                </div>
                <div className={cardClasses.header.screen.tutorBotDescription.container}>
                    <span className={cardClasses.header.screen.tutorBotDescription.mainText(open)}>
                        {frame.tutorBotDescription.mainText}
                    </span>
                </div>
            </div>
        </div>
    );
}

function Body({
    frame,
    examFrameEntry,
    streams,
}: {
    frame: Frame;
    examFrameEntry: ExamFrameEntry;
    streams: Stream<true>[];
}) {
    const challenges = (frame.tutorBotDescription as ChallengesTutorBotDescription)
        .challenges as ChallengeDescription[];
    const challengeDescriptionsById: Record<string, ChallengeDescription> = challenges
        ? challenges.reduce((acc: Record<string, ChallengeDescription>, challengeDescription: ChallengeDescription) => {
              acc[challengeDescription.id] = challengeDescription;
              return acc;
          }, {})
        : {};

    return (
        <div className={cardClasses.body.container}>
            {!examFrameEntry.challenges?.length && (
                <p className={cardClasses.body.noChallengesMessage}>No challenges included on this slide.</p>
            )}
            {examFrameEntry.challenges.map((examChallengeEntry, challengeIndex) => {
                const challengeDescription = challengeDescriptionsById[examChallengeEntry.challengeId];
                if (!challengeDescription) {
                    throw new Error('No challenge description found for challenge');
                }
                return (
                    <ExamChallengeCard
                        key={examChallengeEntry.challengeId}
                        challengeIndex={challengeIndex}
                        challengeDescription={challengeDescription}
                        examChallengeEntry={examChallengeEntry}
                        streams={streams}
                    />
                );
            })}
        </div>
    );
}

function ExamFrame({
    frame,
    coverageMaps,
    lesson,
    frameIndex,
    streams,
}: {
    frame: Frame;
    coverageMaps: CoverageMaps;
    lesson: Lesson<true>;
    frameIndex: number;
    streams: Stream<true>[];
}) {
    const examFrameEntry = coverageMaps.examFrameEntries.find(entry => entry.frameId === frame.id);
    if (!examFrameEntry) throw new Error('No exam frame entry found for frame');

    return (
        <ExpandableCardList
            header={<Header frame={frame} lesson={lesson} frameIndex={frameIndex} examFrameEntry={examFrameEntry} />}
            body={<Body frame={frame} examFrameEntry={examFrameEntry} streams={streams} />}
            cardClassName={cardClasses.cardClassName}
            buttonClassName={cardClasses.buttonClassName}
        />
    );
}

const classes = {
    frameListHeader: clsx(['frame-list-header', 'tw-mb-[15px]', 'tw-p-3']),
    levelOfThinking: {
        container: 'tw-mb-8',
        header: clsx(['tw-mb-2', 'tw-text-md', 'tw-font-semibold']),
    },
    showAllScreens: {
        label: clsx(['tw-ms-2', 'tw-text-[11px]', 'tw-text-gray-400']),
        warningIcon: '-tw-mt-[3px]',
    },
    framesWithWarnings: {
        container: clsx(['tw-my-2', 'tw-text-coral']),
        warningIcon: '-tw-mt-[3px] tw-me-1',
    },
    lesson: {
        container: 'tw-mb-8',
        title: clsx(['tw-mb-4', 'tw-text-lg', 'tw-font-bold']),
    },
};

export const ShowExamFrames: FC<Props> = memo(({ streams, coverageMaps }) => {
    const [showAllScreens, setShowAllScreens] = useState(false);
    const examStream: Stream<true> | undefined = useMemo(() => streams.find(stream => stream.exam), [streams]);
    const shouldShowFrame = (frame: Frame) =>
        showAllScreens ||
        coverageMaps.examFrameEntries.some(
            entry => entry.frameId === frame.id && entry.challenges?.some(challengeShouldHaveWarning),
        );

    const allFrames = useMemo(() => examStream?.lessons.flatMap(lesson => lesson.frames) ?? [], [examStream]);
    const framesWithWarnings = useMemo(
        () =>
            allFrames.filter(frame => {
                const relatedEntry = coverageMaps.examFrameEntries.find(entry => entry.frameId === frame.id);
                return relatedEntry?.challenges?.some(challengeShouldHaveWarning);
            }),
        [allFrames, coverageMaps],
    );

    const allChallenges = useMemo(
        () => coverageMaps.examFrameEntries.flatMap(entry => entry.challenges ?? []),
        [coverageMaps],
    );

    const challengeLevelOfThinkingBreakdown = useMemo(
        () =>
            allChallenges.reduce((acc, challenge) => {
                const label = challenge.levelOfThinking ?? 'unknown';
                if (!acc[label]) {
                    acc[label] = 0;
                }
                acc[label] += 1;
                return acc;
            }, {} as Record<string, number>),
        [allChallenges],
    );

    const levelOfThinkingData = useMemo(
        () =>
            // Ensure consistent ordering of levels of thinking labels
            Object.keys(LEVELS_OF_THINKING).map(levelOfThinking => {
                const count = challengeLevelOfThinkingBreakdown[levelOfThinking] ?? 0;
                const percentage = (count / allChallenges.length) * 100;
                const percentageLabel = percentage < 1 && percentage > 0 ? '<1' : Math.floor(percentage);
                return {
                    id: levelOfThinking,
                    label: `${startCase(levelOfThinking)} ${percentageLabel}%`,
                    value: count,
                    color: LEVELS_OF_THINKING[levelOfThinking as keyof typeof LEVELS_OF_THINKING].labelColor,
                };
            }),
        [challengeLevelOfThinkingBreakdown, allChallenges.length],
    );

    return (
        <>
            <div className={classes.frameListHeader}>
                <div className={classes.levelOfThinking.container}>
                    <h2 className={classes.levelOfThinking.header}>
                        Level of Thinking Across {allChallenges.length} Challenges
                    </h2>
                    <PieChart
                        series={[
                            {
                                arcLabel: item => `${Math.floor((item.value / allChallenges.length) * 100)}%`,
                                arcLabelMinAngle: 30,
                                data: levelOfThinkingData,
                                cx: 70,
                                outerRadius: 70,
                            },
                        ]}
                        width={700}
                        height={150}
                    />
                </div>
                <div>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showAllScreens}
                                    onChange={event => setShowAllScreens(event.target.checked)}
                                />
                            }
                            label={
                                <span>
                                    Show all screens{' '}
                                    {!showAllScreens && (
                                        <span className={classes.showAllScreens.label}>
                                            Only showing screens with{' '}
                                            <WarningIcon className={classes.showAllScreens.warningIcon} />
                                        </span>
                                    )}
                                </span>
                            }
                        />
                    </FormGroup>
                    {framesWithWarnings.length && (
                        <p className={classes.framesWithWarnings.container}>
                            <WarningIcon className={classes.framesWithWarnings.warningIcon} />
                            {framesWithWarnings.length} out of {allFrames.length} screens contain suggestions for
                            improvement
                        </p>
                    )}
                </div>
            </div>
            {examStream?.lessons.map(lesson => (
                <div key={lesson.id} className={classes.lesson.container}>
                    {/* Only show the lesson title if there is more than one lesson (usually there should be only 1) */}
                    {examStream.lessons.length > 1 && (
                        <div className={classes.lesson.title}>Lesson: {lesson.title}</div>
                    )}
                    {lesson.frames.filter(shouldShowFrame).map((frame: Frame) => (
                        <ExamFrame
                            key={frame.id}
                            frame={frame}
                            coverageMaps={coverageMaps}
                            lesson={lesson}
                            streams={streams}
                            frameIndex={lesson.frames.indexOf(frame)}
                        />
                    ))}
                </div>
            ))}
        </>
    );
}, isEqual);
