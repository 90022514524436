import navigateToAccountingOneStream from './navigateToAccountingOneStream';
import PerformanceTest from './PerformanceTest';
import { type PerformanceTestOptions } from './PerformanceTest.types';

/*
    This test repeatedly launches a lesson and keeps track of how long it takes to get into the lesson
*/
export default class LaunchLessonTest extends PerformanceTest {
    name = 'LaunchLesson';
    #repetitions = 20;
    $injector: angular.auto.IInjectorService;
    timerEventTypes = ['lesson_launch_timer'];

    constructor(name: string, $injector: angular.auto.IInjectorService, opts: PerformanceTestOptions) {
        super(name, $injector, opts);
        this.$injector = $injector;
    }

    async runTest() {
        await navigateToAccountingOneStream(this.$injector);

        for (let i = 0; i < this.#repetitions; i++) {
            // eslint-disable-next-line no-await-in-loop
            await this.#launchLesson();
            // eslint-disable-next-line no-await-in-loop
            await navigateToAccountingOneStream(this.$injector);
            this.logProgress(`${i + 1} of ${this.#repetitions}`);
        }

        this.completeTest();
    }

    #launchLesson() {
        const el = document.querySelector<HTMLAnchorElement>('.lesson-box a');
        if (!el) {
            return new Promise(resolve => {
                setTimeout(resolve, 200);
            });
        }

        return new Promise(resolve => {
            const $rootScope = this.$injector.get('$rootScope');
            const cancelListener = $rootScope.$on('lesson:play', () => {
                cancelListener();
                resolve(undefined);
            });
            el.click();
        });
    }

    #navigateToStudentDashboard() {
        document
            .querySelector<HTMLAnchorElement>('app-menu .app-header-pill[translate="navigation.app_menu.home"]')!
            .click();
    }
}
