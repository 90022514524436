import { uniq } from 'uniq';
import { type Stream } from 'Lessons';
import moize from 'moize';
import {
    type CoverageMaps,
    type CoreConceptCoverageMap,
    type CoreConceptToFrameMap,
    type LearningObjectiveCoverageEntry,
    type LearningObjectiveCoverageMap,
    type LessonCoverageEntry,
    type LessonCoverageMap,
    type StreamCoverageEntry,
    type StreamCoverageMap,
    type CoreConceptCoverageEntry,
    type FrameEntry,
} from './CoverageMaps.types';
import { type ExamFrameEntry, type PlaylistExamEvaluation } from './PlaylistExamEvaluation.types';

const emptyCoverageEntry = {
    coreConceptCount: 0,
    coveredCoreConceptCount: 0,
    coveragePercentage: null,
};

function getInstructionalFrameIdsForCoreConceptMap(streams: Stream<true>[]): Record<string, string[]> {
    const instructionalFrameIdsToCoreConceptIds: Record<string, string[]> = {};
    streams.forEach(stream => {
        stream.streamLessonTutorBotRecords.forEach(streamLessonTutorBotRecord => {
            streamLessonTutorBotRecord.outline?.learningObjectives.forEach(learningObjective => {
                learningObjective.coreConcepts.forEach(coreConcept => {
                    coreConcept.frameIds.forEach(frameId => {
                        instructionalFrameIdsToCoreConceptIds[frameId] =
                            instructionalFrameIdsToCoreConceptIds[frameId] || [];
                        instructionalFrameIdsToCoreConceptIds[frameId].push(coreConcept.id);
                    });
                });
            });
        });
    });
    return instructionalFrameIdsToCoreConceptIds;
}

// Instead of mapping a exam frame id to a list of core concepts, map a core concept id to
// a list of exam frame ids.
function getCoreConceptToFramesMap(
    examFrameEntries: PlaylistExamEvaluation['examFrameEntries'],
    streams: Stream<true>[],
) {
    const instructionalFrameIdsToCoreConceptIds = getInstructionalFrameIdsForCoreConceptMap(streams);
    return examFrameEntries.reduce((coreConceptToFramesMap: CoreConceptToFrameMap, examFrameEntry: ExamFrameEntry) => {
        const instructionalFrameIds = uniq(examFrameEntry.challenges.map(c => c.instructionalFrameIds).flat());
        const coreConceptIds = uniq(
            instructionalFrameIds.map(frameId => instructionalFrameIdsToCoreConceptIds[frameId]).flat(),
        );
        coreConceptIds.forEach(coreConceptId => {
            coreConceptToFramesMap[coreConceptId] = coreConceptToFramesMap[coreConceptId] || [];
            coreConceptToFramesMap[coreConceptId].push(examFrameEntry.frameId);
        });
        return coreConceptToFramesMap;
    }, {});
}

function initializeStreamCoverageEntry(streamId: string): StreamCoverageEntry {
    return {
        streamId,
        ...emptyCoverageEntry,
    };
}

function initializeLessonCoverageEntry(lessonId: string, streamId: string): LessonCoverageEntry {
    return {
        lessonId,
        streamId,
        ...emptyCoverageEntry,
    };
}

function initializeLearningObjectiveCoverageEntry(
    learningObjectiveId: string,
    lessonId: string,
    streamId: string,
): LearningObjectiveCoverageEntry {
    return {
        learningObjectiveId,
        lessonId,
        streamId,
        ...emptyCoverageEntry,
    };
}

function getExamFrameEntryMap(streams: Stream<true>[]): Record<string, FrameEntry> {
    return streams.reduce((frameEntryMap: Record<string, FrameEntry>, stream) => {
        if (!stream.exam) return frameEntryMap;
        stream.lessons.forEach(lesson => {
            lesson.frames.forEach(frame => {
                frameEntryMap[frame.id] = {
                    frameId: frame.id,
                    lessonId: lesson.id,
                    streamId: stream.id,
                    frameIndex: lesson.frames.findIndex(f => f.id === frame.id),
                };
            });
        });

        return frameEntryMap;
    }, {});
}

function getCoverageMaps_(playlistExamEvaluation: PlaylistExamEvaluation, streams: Stream<true>[]): CoverageMaps {
    const coreConceptCoverageMap: CoreConceptCoverageMap = {};
    const learningObjectiveCoverageMap: LearningObjectiveCoverageMap = {};
    const lessonCoverageMap: LessonCoverageMap = {};
    const streamCoverageMap: StreamCoverageMap = {};
    const coreConceptToFramesMap = getCoreConceptToFramesMap(playlistExamEvaluation.examFrameEntries, streams);
    const examFrameEntryLookupMap = getExamFrameEntryMap(streams);

    // Loop through the core concepts in each learning lesson. For each core concept, build a list
    // of the learning frames and the exam frames, with info about the stream and lesson for each frame
    // so that we can find it when we need it in the UI.
    //
    // All the way up the tree, through learning objectives, lessons, and streams, keep track of how
    // many of the core concepts within each level are covered in the exam.
    streams.forEach(stream => {
        const streamId = stream.id;
        const streamEntry = initializeStreamCoverageEntry(streamId);
        streamCoverageMap[streamId] = streamEntry;

        stream.streamLessonTutorBotRecords.forEach(streamLessonTutorBotRecord => {
            const lessonId = streamLessonTutorBotRecord.lessonId;
            const lessonEntry = initializeLessonCoverageEntry(lessonId, streamId);
            lessonCoverageMap[lessonId] = lessonEntry;

            streamLessonTutorBotRecord.outline?.learningObjectives.forEach(learningObjective => {
                const LearningObjectiveEntry = initializeLearningObjectiveCoverageEntry(
                    learningObjective.id,
                    lessonId,
                    streamId,
                );

                learningObjectiveCoverageMap[learningObjective.id] = LearningObjectiveEntry;
                learningObjective.coreConcepts.forEach(coreConcept => {
                    const examFrameIds = coreConceptToFramesMap[coreConcept.id] || [];

                    const coreConceptEntry: CoreConceptCoverageEntry = {
                        coreConceptTitle: coreConcept.title,
                        coreConceptId: coreConcept.id,
                        lessonId,
                        streamId,
                        examFrames: examFrameIds.map(frameId => {
                            const frameEntry = examFrameEntryLookupMap[frameId];
                            if (!frameEntry) {
                                throw new Error('No frame entry found for frame');
                            }
                            return frameEntry;
                        }),
                        learningFrames: coreConcept.frameIds.map(frameId => {
                            const lessonWithFrame = stream.lessons.find(lesson =>
                                lesson.frames.some(frame => frame.id === frameId),
                            );
                            if (!lessonWithFrame) throw new Error('No lesson found for frame');
                            const frameIndex = lessonWithFrame.frames.findIndex(frame => frame.id === frameId);
                            return {
                                frameId,
                                lessonId,
                                streamId,
                                frameIndex,
                            };
                        }),
                    };
                    coreConceptCoverageMap[coreConcept.id] = coreConceptEntry;

                    const entries = [streamEntry, lessonEntry, LearningObjectiveEntry];

                    entries.forEach(entry => {
                        entry.coreConceptCount += 1;
                        if (examFrameIds.length > 0) {
                            entry.coveredCoreConceptCount += 1;
                        }
                        entry.coveragePercentage = entry.coveredCoreConceptCount / entry.coreConceptCount;
                    });
                });
            });
        });
    });

    return {
        stream: streamCoverageMap,
        lesson: lessonCoverageMap,
        learningObjective: learningObjectiveCoverageMap,
        coreConcept: coreConceptCoverageMap,
        examFrameEntries: playlistExamEvaluation.examFrameEntries,
    };
}

export const getCoverageMaps = moize(getCoverageMaps_);

export default getCoverageMaps;
