import navigateToAccountingOneStream from './navigateToAccountingOneStream';
import PerformanceTest from './PerformanceTest';
import { type PerformanceTestOptions } from './PerformanceTest.types';

/*
    This test navigates through the frames of a lesson and keeps track of how long it takes to navigate.

    The speed at which we choose to navigate on this test really effects what it's testing and how realistic
    it is. Going faster than real users go can make it seem like frame navigation is slow, where really if
    you slow down to a more realistic speed, images get preloaded and progress saves complete and everything is fine.
    We use the #timeOnEachFrame property to control this.

    This test navigates by clicking on the hexagons in the lesson progress bar. Regular users are not
    allowed to do that, so this test will not work for a non-admin user in a browser unless you set the
    ...ENABLE_LESSON_HEXAGON_NAVIGATION environment variable.
*/
export default class FrameNavigationTest extends PerformanceTest {
    name = 'FrameNavigation';

    #timeOnEachFrame = 10000;
    #repetitions = 17; // number of frames in the target lesson
    $rootScope: angular.IRootScopeService;
    safeApply: (scope: angular.IRootScopeService) => void;
    $location: angular.ILocationService;
    $injector: angular.auto.IInjectorService;
    timerEventTypes = ['frame_navigation_timer'];

    constructor(name: string, $injector: angular.auto.IInjectorService, opts: PerformanceTestOptions) {
        super(name, $injector, opts);
        this.$injector = $injector;
        this.$rootScope = $injector.get('$rootScope');
        this.$location = $injector.get('$location');
        this.safeApply = $injector.get('safeApply') as (scope: angular.IRootScopeService) => void;
    }

    async runTest() {
        await navigateToAccountingOneStream(this.$injector);
        await this.#launchLesson();

        for (let i = 0; i < this.#repetitions; i++) {
            // eslint-disable-next-line no-await-in-loop
            await this.#clickFrameHexagonAndWaitToNavigate(i);
            this.logProgress(`${i + 1} of ${this.#repetitions}`);
        }

        this.completeTest();
    }

    #clickFrameHexagonAndWaitToNavigate(frameIndex: number) {
        const el = document.querySelectorAll<HTMLButtonElement>('.progress-container button.indicator')[frameIndex];
        return new Promise(resolve => {
            const cancelListener = this.$rootScope.$on('lesson:frame_started', () => {
                cancelListener();
                setTimeout(resolve, this.#timeOnEachFrame);
            });
            el!.click();
        });
    }

    #launchLesson() {
        const el = document.querySelector<HTMLAnchorElement>('.lesson-box a');
        if (!el) {
            return new Promise(resolve => {
                setTimeout(resolve, 200);
            });
        }

        return new Promise(resolve => {
            const cancelListener = this.$rootScope.$on('lesson:play', () => {
                cancelListener();
                resolve(undefined);
            });
            el.click();
        });
    }

    #navigateToStreamDashboard() {
        const path = '/course/50eeda2d-1b44-4a1d-b425-dead8982526f';
        if (this.$location.url() === path) {
            return Promise.resolve();
        }

        let cancelListener;
        const promise = new Promise(resolve => {
            cancelListener = this.$rootScope.$on('renderedStreamDashboard', resolve);
        });

        this.$location.url(path);
        this.safeApply(this.$rootScope);
        return promise.then(cancelListener);
    }

    #navigateToStudentDashboard() {
        document
            .querySelector<HTMLAnchorElement>('app-menu .app-header-pill[translate="navigation.app_menu.home"]')!
            .click();
    }
}
