import { frameEditorLink } from 'Lessons';
import { memo, type FC, type AnchorHTMLAttributes, type ImgHTMLAttributes } from 'react';
import ReactMarkdown from 'react-markdown';
import { type FrameDescriptor } from './CoverageMaps.types';
import { WarningIcon } from './WarningIcon';

type Props = { children: string; frameDescriptors: FrameDescriptor[] };

const MarkDownLink = ({ children, ...rest }: AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <a
        {...rest}
        className="tw-text-coral tw-transition hover:tw-text-coral-hover focus-visible:tw-outline focus-visible:tw-outline-1"
    >
        {children}
    </a>
);
const screenProtocolRegex = /(\[([^\]]+)\])?\[screen:\/\/([^\]]+)\]/g;

function CustomImage({ src, alt, ...imageRest }: ImgHTMLAttributes<HTMLImageElement>) {
    if (src === 'warningIcon') {
        return <WarningIcon className="tw-me-2" />;
    }
    return <img src={src} alt={alt} {...imageRest} />;
}

const replaceScreenLinks = (markdown: string, frameDescriptors: FrameDescriptor[]): string =>
    markdown.replace(screenProtocolRegex, (_: string, linkTextContainer: string, linkText: string, frameId: string) => {
        const frameDescriptor = frameDescriptors.find(
            candidateFrameDescriptor => candidateFrameDescriptor.frameId === frameId,
        );
        if (frameDescriptor) {
            const httpLink = frameEditorLink(frameDescriptor);
            const finalLinkText = linkTextContainer ? linkText : `Screen ${frameDescriptor.frameIndex + 1}`;
            return `[${finalLinkText}](${httpLink})`;
        }

        return linkText;
    });

export const PlaylistExamEvaluationMarkdown: FC<Props> = memo(({ children, frameDescriptors }) => {
    const modifiedText = replaceScreenLinks(children, frameDescriptors);

    return (
        <ReactMarkdown
            components={{
                a: MarkDownLink,
                img: CustomImage,
            }}
        >
            {modifiedText}
        </ReactMarkdown>
    );
});
