import { isEqual } from 'lodash/fp';
import { memo, type FC } from 'react';
import { type CoreConcept } from 'StreamLessonTutorBotRecord';
import { type CoverageMaps } from './CoverageMaps.types';
import { FrameLinkList } from './FrameLinkList';

type Props = {
    coreConcept: CoreConcept;
    coverageMaps: CoverageMaps;
};

export const ShowCoreConceptCoverage: FC<Props> = memo(({ coreConcept, coverageMaps }) => {
    const entry = coverageMaps.coreConcept[coreConcept.id];

    return (
        <div className="tw-mx-4 tw-mb-4 tw-rounded-lg tw-border tw-border-beige-medium tw-bg-gray-50 tw-p-4">
            <p className="tw-mb-4">
                <strong className="tw-font-semibold">Core Concept: {coreConcept.title}</strong>
            </p>
            <div>
                <strong className="tw-font-semibold">Learned in Lesson</strong>
                <ul className="tw-mb-4 tw-ms-5 tw-mt-1 tw-list-disc">
                    <li>
                        <FrameLinkList frameEntries={entry.learningFrames} />
                    </li>
                </ul>
            </div>
            <div>
                <strong className="tw-font-semibold">Tested in Exam</strong>
                {entry.examFrames.length === 0 && (
                    <p className="tw-mb-4 tw-ms-5 tw-mt-1 tw-italic tw-text-gray-700">No exam frames found.</p>
                )}
                {entry.examFrames.length > 0 && (
                    <ul className="tw-mb-4 tw-ms-5 tw-mt-1 tw-list-disc">
                        <li>
                            <FrameLinkList frameEntries={entry.examFrames} />
                        </li>
                    </ul>
                )}
            </div>
        </div>
    );
}, isEqual);
