// See comment in https://stackoverflow.com/a/21653600/1747491
function formatDateToParam(date) {
    return date ? date.toISOString().replace(/-|:|\.\d+/g, '') : '';
}

// See https://tools.ietf.org/html/rfc5545#section-3.3.11
//  The "TEXT" property values may also contain special characters
//  that are used to signify delimiters, such as a COMMA character for
//  lists of values or a SEMICOLON character for structured values.
//  In order to support the inclusion of these special characters in
//  "TEXT" property values, they MUST be escaped with a BACKSLASH
//  character.  A BACKSLASH character in a "TEXT" property value MUST
//  be escaped with another BACKSLASH character.  A COMMA character in
//  a "TEXT" property value MUST be escaped with a BACKSLASH
//  character.  A SEMICOLON character in a "TEXT" property value MUST
//  be escaped with a BACKSLASH character.  However, a COLON character
//  in a "TEXT" property value SHALL NOT be escaped with a BACKSLASH
//  character.
//
//  DESCRIPTION - https://tools.ietf.org/html/rfc5545#section-3.8.1.5
//  LOCATION - https://tools.ietf.org/html/rfc5545#section-3.8.1.7
//  SUMMARY - https://tools.ietf.org/html/rfc5545#section-3.8.1.12
//
// Also note the trickyness regarding newlines. The spec states that
// newlines should be encoded as "\n". But you have to remember that
// JavaScript will consume that as a newline character. So to get "\n"
// to the ICS field as a literal string you need to use "\\n". But then you
// have to make sure you aren't doubly escaping, since backslashes are themselves
// supposed to be escaped. Tricky tricky.
// See https://stackoverflow.com/a/667274/1747491
function escapeForICS(value) {
    if (!value) {
        return null;
    }

    // First escape backslash, comma, or semicolon characters
    let escapedValue = value.replace(/\\|,|;/g, char => `\\${char}`);

    // Then escape any newline characters
    escapedValue = escapedValue.replace(/\n/g, '\\n');
    return escapedValue;
}

// TODO: Consider switching to the official gapi library?
// For unofficial documentation for the HTTP API see:
//  https://stackoverflow.com/a/23495015/1747491
//  https://stackoverflow.com/a/21653600/1747491
function google(event) {
    let startDateParam;
    let endDateParam;

    if (event.allday) {
        // TODO: support allday
    } else {
        startDateParam = formatDateToParam(event.startDate);
        endDateParam = formatDateToParam(event.endDate);
    }

    // This http API supports a timezone param, `ctz`, but since
    // the browser should handle converting the UTC date I'm not
    // going to bother supporting it right now.
    return [
        'https://www.google.com/calendar/render',
        '?action=TEMPLATE',
        `&text=${window.encodeURIComponent(event.title)}`,
        `&dates=${window.encodeURIComponent(startDateParam)}`,
        `/${window.encodeURIComponent(endDateParam)}`,
        `&details=${window.encodeURIComponent(event.description)}`,
        `&location=${window.encodeURIComponent(event.address)}`,
        '&sprop=&sprop=name:',
    ].join('');
}

// TODO: See if I can find more documentation about the /owa request used
// Note that OWA stands for Outlook Web Access. This method allows an
// additional parameter to support personal Outlook accounts
// NOTE: There is no official documentation for the outlook links but the
// following 3rd party documentation is accurate from my testing
// https://interactiondesignfoundation.github.io/add-event-to-calendar-docs/services/outlook-web.html
function outlook(event, account_type) {
    // TODO: DRY some of this up with the other builder methods?
    let startDateParam;
    let endDateParam;

    if (event.allday) {
        // TODO: support allday
    } else {
        // Outlook requires a different date format
        startDateParam = event.startDate ? event.startDate.toISOString() : event.startDate;
        endDateParam = event.endDate ? event.endDate.toISOString() : event.endDate;
    }

    return [
        `https://outlook.${account_type}.com/calendar/0/deeplink/compose`,
        '?path=/calendar/action/compose',
        '&rru=addevent',
        `&subject=${window.encodeURIComponent(event.title)}`,
        `&startdt=${window.encodeURIComponent(startDateParam)}`,
        `&enddt=${window.encodeURIComponent(endDateParam)}`,
        `&body=${window.encodeURIComponent(event.description)}`,
        `&location=${window.encodeURIComponent(event.address)}`,
        `&allday=${window.encodeURIComponent(event.allday)}`,
    ].join('');
}

function yahoo() {
    // TODO: Maybe?
    // See https://git.io/JeG0C
    throw new Error('Yahoo not implemented');
}

/**
 * Creates an ICS file
 *
 * @param {OuiEvent} event
 * @param {'string' | 'blob' | 'uri'} returnType - The desired return type of the ICS file
 *
 */
function ics(event, prodid, returnType = 'string') {
    const startDateParam = formatDateToParam(event.startDate);
    const endDateParam = formatDateToParam(event.endDate);
    const stampDateParam = formatDateToParam(new Date());

    if (event.allday) {
        // TODO: Support allday
    } else if (event.timezone) {
        // TODO: You can specify timezone information in the ICS, though it
        // looks to be a pain (see https://stackoverflow.com/a/35649729/1747491).
        // I'm not sure that we need to support it though, as all calendars I've
        // tested in seem to take a UTC date and properly format it for the
        // client's timezone.
    }

    // TODO: See if there is a minimal library for generating this. Seems like
    // there should be.
    // See this handy validator -- https://icalendar.org/validator.html
    const fileContents = [
        'BEGIN:VCALENDAR',
        `PRODID:${prodid}`,
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `URL:${event.url}`,
        `DTSTART:${startDateParam}`,
        `DTEND:${endDateParam}`,
        `DTSTAMP:${stampDateParam}`,
        `SUMMARY:${escapeForICS(event.title)}`,
        `DESCRIPTION:${escapeForICS(event.description)}`,
        `LOCATION:${escapeForICS(event.address)}`,
        `UID:${event.id}`,
        'END:VEVENT',
        'END:VCALENDAR',
    ].join('\n');

    // FIXME: There's currently an issue in Firefox mobile, at least on Android
    // See https://bugzilla.mozilla.org/show_bug.cgi?id=1429362
    if (returnType === 'string') {
        return fileContents;
    }
    if (returnType === 'blob') {
        return new Blob([fileContents], {
            type: 'text/calendar',
        });
    }
    if (returnType === 'uri') {
        return window.encodeURI(`data:text/calendar;charset=utf8,${fileContents}`);
    }
    return undefined;
}

const OuiBuilder = {
    google,
    outlook,
    yahoo,
    ics,
};

export default OuiBuilder;
