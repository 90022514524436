import angularModule from 'ProgramSwitcher/angularModule/scripts/program_switcher_module';
import template from 'ProgramSwitcher/angularModule/views/program_switcher.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { type ProgramSwitcherProgramConfig } from 'ProgramSwitcher';
import { type CurrentUserIguanaObject } from 'Users';
import { type IScope } from 'angular';

interface Scope extends IScope {
    currentUser: CurrentUserIguanaObject;
    programConfigs: ProgramSwitcherProgramConfig[];
    switchProgram: (programConfig: ProgramSwitcherProgramConfig) => void;
    isSwitching?: boolean;
    onProgramSwitch?: () => void;
}

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive<Scope>('programSwitcher', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const DialogModal = $injector.get('DialogModal');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                programConfigs: '<',
                onProgramSwitch: '<',
            },
            link(scope) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.switchProgram = (programConfig: ProgramSwitcherProgramConfig) => {
                    if (programConfig.selected) return;

                    scope.isSwitching = true;

                    if (scope.onProgramSwitch) {
                        scope.onProgramSwitch();
                    }

                    scope.currentUser
                        .changePrefProgram(programConfig.userProgramFieldStateId)
                        .then(() => DialogModal.hideAlerts())
                        .finally(() => {
                            scope.isSwitching = false;
                        });
                };
            },
        };
    },
]);
