import { getBackRoyalApiBaseConfig } from 'BackRoyalApi';
import { createApi } from 'ReduxHelpers';
import {
    type PlaylistExamEvaluationIndexResponse,
    type PlaylistExamEvaluationShowResponse,
} from './PlaylistExamEvaluation.types';

export const playlistExamEvaluationsApi = createApi('playlistExamEvaluationsApi', {
    ...getBackRoyalApiBaseConfig('playlistExamEvaluationsApi'),
    endpoints: builder => ({
        getExamEvaluations: builder.query<PlaylistExamEvaluationIndexResponse, void>({
            query: () => ({
                url: 'playlist_exam_evaluations.json',
                method: 'GET',
            }),
        }),
        getExamEvaluation: builder.query<PlaylistExamEvaluationShowResponse, string>({
            query: id => ({
                url: `playlist_exam_evaluations/${id}.json`,
                method: 'GET',
            }),
        }),
    }),
});
