import PerformanceTest from './PerformanceTest';
import { type PerformanceTestOptions } from './PerformanceTest.types';

/*
    This test navigates back and forth between the student dashboard and a stream dashboard
    and times how long it takes between clicking ot navigate to each page and actually landing
    on the page.
*/
export default class DashboardNavigationTest extends PerformanceTest {
    name = 'DashboardNavigation';
    #counter = 0;
    #repetitions = 20;
    #listeners: (() => void)[] = [];
    $rootScope: angular.IRootScopeService;
    $timeout: angular.ITimeoutService;
    timerEventTypes = ['rendered_stream_dashboard', 'rendered_student_dashboard'];

    constructor(name: string, $injector: angular.auto.IInjectorService, opts: PerformanceTestOptions) {
        super(name, $injector, opts);
        this.$rootScope = this.$injector.get('$rootScope');
        this.$timeout = this.$injector.get('$timeout');
    }

    runTest() {
        // I'm not sure the delay is necessary, but it made it easier to watch what
        // was happening and believe that it was reflective of what we were trying to
        // measure in the wild.
        const delay = 500;
        this.#listeners = [
            this.$rootScope.$on('renderedStudentDashboard', () => {
                this.$timeout(delay).then(() => this.#navigateToStreamDashboard());
            }),
            this.$rootScope.$on('renderedStreamDashboard', () => {
                this.#count();
                this.$timeout(delay).then(() => this.#navigateToStudentDashboard());
            }),
        ];

        this.#navigateToStreamDashboard();
    }

    #navigateToStreamDashboard() {
        document.querySelector<HTMLAnchorElement>('stream-link-box a')!.click();
    }

    #navigateToStudentDashboard() {
        document
            .querySelector<HTMLAnchorElement>('app-menu .app-header-pill[translate="navigation.app_menu.home"]')!
            .click();
    }

    #count() {
        this.#counter += 1;

        this.logProgress(`${this.#counter} of ${this.#repetitions}`);
        if (this.#counter >= this.#repetitions) {
            this.#completeTest();
        }
    }

    #completeTest() {
        this.#listeners.forEach(cancel => cancel());
        super.completeTest();
    }
}
