// See docs/client_performance_tests.md

import { type AnyObject } from '@Types';
import type TimerSingleton from 'FrontRoyalTimer/TimerSingleton';
import { generateGuid } from 'guid';
import { type PerformanceTestOptions } from './PerformanceTest.types';

export default abstract class PerformanceTest {
    $injector: angular.auto.IInjectorService;
    id: string;
    timerSingleton: TimerSingleton;
    complete = false;
    name!: string;
    situation: string;
    timerEventTypes!: string[];
    started = false;
    resolveRunTestPromise?: (value: unknown) => void;
    #onTimerStep?: (eventType: string, duration: number, logInfo: AnyObject) => void;
    #logLevel;

    constructor(
        situation: string,
        $injector: angular.auto.IInjectorService,
        { logLevel }: PerformanceTestOptions = {},
    ) {
        this.$injector = $injector;
        this.timerSingleton = $injector.get('timerSingleton');
        this.id = generateGuid();
        this.situation = situation;
        this.#logLevel = logLevel;
    }

    startTest() {
        if (this.started) {
            throw new Error('Test is already started');
        }
        this.started = true;
        this.#onTimerStep = (eventType, duration, logInfo) => {
            if (this.timerEventTypes.includes(eventType)) {
                logInfo.performance_test_name = this.name;
                logInfo.performance_test_id = this.id;
                logInfo.performance_test_situation = this.situation;
                this.afterRecordDataPoint(eventType, duration, logInfo);
            }
        };
        this.timerSingleton.on('timerStep', this.#onTimerStep);

        // This kicks off an async process, but we don't wait for it. We return the promise, which
        // will eventually be resolved when completeTest is called
        this.runTest();

        return new Promise(resolve => {
            this.resolveRunTestPromise = resolve;
        });
    }

    // subclasses should override this
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    runTest() {}

    completeTest() {
        this.complete = true;
        this.resolveRunTestPromise!(undefined);
        this.timerSingleton.off('timerStep', this.#onTimerStep!);
    }

    logProgress(msg: string) {
        if (this.#logLevel === 'info') {
            // eslint-disable-next-line no-console
            console.info(`${this.name}: ${msg}`);
        }
    }

    afterRecordDataPoint(_eventType: string, _duration: number, _logInfo: AnyObject) {
        // This can be overridden by subclasses
    }
}
