/* eslint-disable react/jsx-wrap-multilines */
import clsx from 'clsx';
import { type Stream, getOrderedLessonsFromStream } from 'Lessons';
import { isEqual } from 'lodash/fp';
import { ExpandableCardList } from 'ExpandableContainer';
import { memo, type FC } from 'react';
import { ShowLessonCoverage } from './ShowLessonCoverage';
import { type CoverageMaps } from './CoverageMaps.types';

type Props = {
    stream: Stream<true>;
    examStream: Stream<true>;
    coverageMaps: CoverageMaps;
};

const classes = {
    image: clsx(['tw-h-[30px]', 'tw-w-[30px]', 'tw-rounded-full']),
    title: {
        primary: clsx(['tw-inline-block', 'tw-ms-2.5', 'tw-text-xs', 'min-[365px]:tw-text-base', 'tw-font-semibold']),
    },
    body: 'tw-m-4',
    card: clsx(['tw-p-2', 'tw-mb-4']),
};

export const ShowStreamCoverage: FC<Props> = memo(({ stream, coverageMaps }) => {
    const entry = coverageMaps.stream[stream.id];
    const coveragePercent = entry.coveragePercentage == null ? null : Math.round(100 * entry.coveragePercentage);
    const coveragePercentText = coveragePercent == null ? '' : `(${coveragePercent}% coverage)`;

    return (
        <ExpandableCardList
            header={
                <>
                    <img src={stream.image.formats['50X50'].url} alt={stream.title} className={classes.image} />
                    <div className={clsx(classes.title.primary)}>
                        {coveragePercentText} {stream.title}
                    </div>
                </>
            }
            body={
                <div className={classes.body}>
                    {getOrderedLessonsFromStream(stream).map(
                        lesson =>
                            !lesson.assessment && (
                                <ShowLessonCoverage
                                    key={lesson.id}
                                    stream={stream}
                                    lesson={lesson}
                                    coverageMaps={coverageMaps}
                                />
                            ),
                    )}
                </div>
            }
            cardClassName={classes.card}
        />
    );
}, isEqual);
