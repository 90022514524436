export default class OuiEvent {
    MS_IN_MINUTES = 60 * 1000;

    constructor(params) {
        this.id = params.id || Math.floor(Math.random());
        this.title = params.title || 'New Event';
        this.startDate = params.startDate || new Date();
        this.description = params.description || '';
        this.address = params.address || '';
        this.url = params.url || '';

        if (params.duration) {
            this.endDate = new Date(this.startDate.getTime() + this.MS_IN_MINUTES * 60);
        } else {
            this.endDate = params.endDate || new Date(this.startDate.getTime() + this.MS_IN_MINUTES * 60);
        }

        // For ICS generation
        this.timezone = params.timezone;

        // TODO: support allday
        this.allday = false;
    }
}
